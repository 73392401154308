import MDButton from 'components/MDButton'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import { useUserInfo } from 'context/user'
import MDInput from 'components/MDInput'
import { useParams } from 'react-router-dom'
import Editor from 'components/editor'
import { TextareaAutosize } from '@mui/material'

const EditModal = ({ data, handleRefresh }) => {
  const uploadImage = useUploadImage()
  const { languageId } = useParams()
  const updateCategory = data?.languages?.some((language) => language.languageId === languageId)
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const AppService = useAppServices()
  const [user] = useUserInfo()
  var axios = require('axios')
  const handlemodal = () => {
    setOpenAddProduct(true)
  }
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false)
    const [editor, setEditor] = useState(
      data?.languages?.filter((language) => language.languageId === languageId)[0]?.doc || ''
    )

    const handleSubmit = async (e) => {
      e.preventDefault()
      setProcessing(true)
      // console.log(data)
      let image_url = data?.languages?.filter((language) => language.languageId === languageId)[0]
        ?.image_url

      // Check if a new image is provided
      if (e.target.image.files.length > 0) {
        image_url = await uploadImage({
          file: e.target.image.files[0],
          desiredPath: `design/logo/image`,
        })
          .then((res) => res.response.data)
          .catch((err) => null)
      }
      const language = {
        name: e.target.name.value,
        description: e.target.description.value,
        image_url: image_url,
        languageId: languageId,
      }
      if (updateCategory) {
        const languages = data?.languages
        const indexToUpdate = languages?.findIndex((language) => language.languageId === languageId)
        if (indexToUpdate !== -1) {
          languages[indexToUpdate] = language
        }
        var payload = {
          languages: [...languages],
          _id: data._id,
        }
      } else {
        var payload = {
          languages: [...data?.languages, language],
          _id: data._id,
        }
      }

      // console.log(payload)

      const { response } = await AppService.docs_category.updateDocs({
        payload: payload,
      })
      // console.log(response)
      if (response) {
        setProcessing(false)
        onClose()
        handleRefresh()
      }
    }
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px  #000',
      boxShadow: 24,
      p: 4,
    }
    return (
      <MDModal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox>
          <MDBox component="form" onSubmit={handleSubmit} role="form" sx={style}>
            <MDTypography variant="h5" mb={2}>
              {updateCategory ? ' Update Category' : ' Create Category'}
            </MDTypography>
            <MDBox my={2}>
              <MDInput
                type="text"
                label="Name"
                name="name"
                defaultValue={
                  data?.languages?.filter((language) => language.languageId === languageId)[0]?.name
                }
                fullWidth
              />
            </MDBox>
            <MDBox my={2}>
              <MDInput type="file" name="image" fullWidth />
            </MDBox>
            <MDBox my={2}>
              <TextareaAutosize
                className="form-control"
                style={{ minHeight: '70px' }}
                placeholder="Description"
                name="description"
                defaultValue={
                  data?.languages?.filter((language) => language.languageId === languageId)[0]
                    ?.description
                }
              />
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"
                color="primary"
                type="button"
                sx={{ mt: 4, mb: 1 }}
                onClick={closeAddProduct}
              >
                close
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1, ml: 1 }}
                loading={processing}
                disabled={processing}
              >
                {updateCategory ? 'update' : 'Create'}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    )
  }
  const closeAddProduct = () => {
    // if (subscription?._id)
    setOpenAddProduct(false)
  }
  return (
    <>
      {updateCategory ? (
        <MDButton variant="contained" color="success" size="small" onClick={handlemodal}>
          Edit
        </MDButton>
      ) : (
        <MDButton variant="contained" color="error" size="small" onClick={handlemodal}>
          Create
        </MDButton>
      )}
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  )
}

export default EditModal
