import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import { useAppServices } from 'hook/services'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px  #000',
  boxShadow: 24,
  p: 4,
}
function MoveSubCategory({ data, handleRefresh }) {
  const [open, setopen] = useState(false)

  function ModelWrappar({ open, onClose }) {
    const { state } = useLocation()
    const { categories } = state

    // alert(state._id)
    const [processing, setProcessing] = useState(false)
    const AppService = useAppServices()
    const handleSubmit = async (e) => {
      e.preventDefault()
      setProcessing(true)
      const payload = {
        _id: data._id,
        category: e.target.category.value,
      }
      const { response } = await AppService.docs_sub_category.move({
        payload,
        toaster: true,
      })
      setProcessing(false)
      if (response) {
        onClose()
        handleRefresh()
      }
    }

    return (
      <MDBox>
        <MDModal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
            <MDTypography variant="h6">Move Sub Category</MDTypography>
            <MDBox sx={{ fontSize: '15px' }}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Category
              </MDTypography>
              <Select
                required
                name="category"
                closeMenuOnSelect={true}
                defaultValue={{
                  label: categories?.find((item) => item._id == data?.category)?.name,
                  value: categories?.find((item) => item._id == data?.category)?._id,
                }}
                options={categories?.map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
              />
            </MDBox>
            <MDButton
              variant="gradient"
              color="info"
              type="submit"
              className="brand-card-background"
              loading={processing}
              disabled={processing}
              sx={{ mt: 4, mb: 1 }}
              fullWidth
            >
              Move
            </MDButton>
          </MDBox>
        </MDModal>
      </MDBox>
    )
  }
  return (
    <>
      <MDButton
        onClick={() => setopen(true)}
        variant="gradient"
        color="warning"
        style={{ marginRight: '10px' }}
      >
        Move
      </MDButton>
      <ModelWrappar open={open} onClose={() => setopen(false)} />
    </>
  )
}
export default MoveSubCategory
