import React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { useEffect } from 'react'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

const Singleselect = (props) => {
  const theme = useTheme()
  const [personName, setPersonName] = React.useState([])
  const names = props.data
  const onLoad = () => {
    // alert(props.edit_data)
    if (props.edit_data != '' && props.edit_data != undefined) {
      setPersonName(
        // On autofill we get a stringified value.
        typeof props.edit_data === 'string' ? props.edit_data?.split(',') : props.edit_data
      )
    }
  }
  useEffect(onLoad, [props.edit_data])
  //  console.log(personName,'personName')

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
    // console.log(value)
  }

  return (
    <>
      <FormControl sx={{ m: 0, width: '100%' }} className="w-100">
        <InputLabel id="demo-multiple-chip-label">{props.name}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          single
          name={props.name}
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names?.map((name) => (
            <MenuItem key={name.id} value={name.id} style={getStyles(name, personName, theme)}>
              {name.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default Singleselect
