/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import * as yup from 'yup'
// Data
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import DataTable from 'examples/Tables/DataTable'
import projectsTableData from '../data/projectsTableData'
import Loader from 'examples/Loader'
function DocsInsight() {
  const { state } = useLocation()

  const { columns: pColumns, rows: pRows, loader } = projectsTableData()
  const navigate = useNavigate()
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loader ? (
        <Loader />
      ) : (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="primary"
                  display="flex"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Docs
                  </MDTypography>
                  <MDButton
                    onClick={() => navigate('./create', { state: { SubCategory: state } })}
                    sx={{
                      marginLeft: 'auto',
                      // '10px'
                    }}
                    variant="outlined"
                  >
                    Create
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: pColumns, rows: pRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  )
}

export default DocsInsight
