/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CategoryModalEdit from '../Modals/CategoryModalEdit'
import FaqModal from '../Modals/FaqModalEdit'
import FaqVideoEditModal from '../Modals/FaqVideoEditModal'

//
export default function DataTableData() {
  const AppService = useAppServices()
  const params = useParams()
  const navigate = useNavigate()
  const [faq, setFaq] = useState([])
  const [faqVideos, setFaqVideos] = useState([])
  const [categries, setCategries] = useState([])
  const [loader, setloader] = useState(true)

  const getFaqs = async () => {
    const { response, error } = await AppService.faq_category.unlimited_data({
      query: `type=unlimited`,
    })
    // console.log(response, 'response')
    if (response) {
      setCategries(response.data.map((item) => ({ ...item, label: item.name, value: item._id })))
      setFaq(response.faq)
      setFaqVideos(response.faq_videos)
      setloader(false)
    } else {
      setCategries([])
      setFaq([])
      setloader(false)
    }
  }
  const onLoad = () => {
    getFaqs()
  }
  const handleCategoryDelete = async (id) => {
    const { response } = await AppService.faq_category.delete({
      query: `_id=${id}`,
    })
    if (response) onLoad()
  }
  const handleFaqDelete = async (id) => {
    const { response } = await AppService.faq.delete({
      query: `_id=${id}`,
    })
    if (response) onLoad()
  }
  useEffect(onLoad, [])
  return {
    loader: loader,
    categries: categries,
    handleRefresh: onLoad,
    CategoryDataTableData: {
      columns: [
        { Header: 'name', accessor: 'name' },
        { Header: 'Action', accessor: 'action' },
      ],

      rows: categries?.map((data, index) => ({
        name: data.name,
        action: (
          <div className="d-flex">
            <CategoryModalEdit handleRefresh={onLoad} editData={data} />
            <MDBox>
              <MDButton
                variant="contained"
                color="error"
                size="small"
                sx={{ marginLeft: 2 }}
                onClick={() => handleCategoryDelete(data._id)}
              >
                Delete
              </MDButton>
            </MDBox>
          </div>
        ),
      })),
    },
    FaqDataTableData: {
      columns: [
        { Header: 'question', accessor: 'question' },
        { Header: 'Action', accessor: 'action' },
      ],

      rows: faq?.map((data, index) => ({
        question: data.question,
        action: (
          <MDBox>
            <FaqModal categries={categries} handleRefresh={onLoad} editData={data} />
            <MDButton
              variant="contained"
              color="error"
              size="small"
              sx={{ marginLeft: 2 }}
              onClick={() => handleFaqDelete(data._id)}
            >
              Delete
            </MDButton>
          </MDBox>
        ),
      })),
    },
    FaqVideosDataTableData: {
      columns: [
        { Header: 'name', accessor: 'name' },
        { Header: 'url', accessor: 'url' },
        { Header: 'Action', accessor: 'action' },
      ],

      rows: faqVideos?.map((data, index) => ({
        name: data.name,
        url: data.url,
        action: (
          <MDBox>
            <FaqVideoEditModal categries={categries} handleRefresh={onLoad} editData={data} />
            <MDButton
              variant="contained"
              color="error"
              size="small"
              sx={{ marginLeft: 2 }}
              onClick={() => handleFaqDelete(data._id)}
            >
              Delete
            </MDButton>
          </MDBox>
        ),
      })),
    },
  }
}
