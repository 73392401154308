/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import EditModal from '../Modals/Edit'

//
export default function DataTableData() {
  const AppService = useAppServices()
  const params = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [loader, setloader] = useState(true)

  const getData = async () => {
    const { response, error } = await AppService.pages.get({
      query: ``,
    })
    // console.log(response, 'response')
    if (response) {
      setData(response.data)
      setloader(false)
    } else {
      setData([])
      setloader(false)
    }
  }
  const onLoad = () => {
    getData()
  }
  const handleDelete = async (id) => {
    const { response } = await AppService.pages.delete({
      query: `_id=${id}`,
    })
    if (response) onLoad()
  }
  useEffect(onLoad, [])
  return {
    loader: loader,
    data: data,
    handleRefresh: onLoad,
    TableData: {
      columns: [
        { Header: 'name', accessor: 'name' },
        { Header: 'Action', accessor: 'action' },
      ],

      rows: data?.map((data, index) => ({
        name: data.name,
        action: (
          <div className="d-flex">
            <EditModal handleRefresh={onLoad} editData={data} />
            <MDButton
              variant="gradient"
              color="warning"
              sx={{ marginLeft: 2 }}
              onClick={() => navigate(`./${data._id}`, { state: data })}
            >
              view
            </MDButton>
            <MDBox>
              <MDButton
                variant="contained"
                color="error"
                size="small"
                sx={{ marginLeft: 2 }}
                onClick={() => handleDelete(data._id)}
              >
                Delete
              </MDButton>
            </MDBox>
          </div>
        ),
      })),
    },
  }
}
