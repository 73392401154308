import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import { useAppServices } from 'hook/services'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px  #000',
  boxShadow: 24,
  p: 4,
}
function MoveDoc({ data, handleRefresh, categories, subCategories }) {
  const [open, setopen] = useState(false)

  function ModelWrappar({ open, onClose }) {
    const [selectedCategory, setselectedCategory] = useState(
      categories?.find((item) => item.value == data?.category)?.value
    )
    const [selectedSubCategory, setSelectedSubCategory] = useState(
      subCategories?.find((item) => item.value == data?.sub_category)
    )
    const [processing, setProcessing] = useState(false)
    const AppService = useAppServices()
    const handleSubmit = async (e) => {
      e.preventDefault()
      setProcessing(true)
      const payload = {
        _id: data._id,
        category: e.target.category.value,
        sub_category: e.target.sub_category.value,
      }
      const { response } = await AppService.docs.move({
        payload,
        toaster: true,
      })
      setProcessing(false)
      if (response) {
        onClose()
        handleRefresh()
      }
    }

    return (
      <MDBox>
        <MDModal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
            <MDTypography variant="h6">Move Sub Category</MDTypography>
            <MDBox sx={{ fontSize: '15px' }} mb={2}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Category
              </MDTypography>
              <Select
                onChange={(e) => {
                  setselectedCategory(e.value)
                  setSelectedSubCategory(null)
                }}
                required
                name="category"
                closeMenuOnSelect={true}
                defaultValue={categories?.find((item) => item.value == data?.category)}
                options={categories}
              />
            </MDBox>
            <MDBox sx={{ fontSize: '15px' }}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Sub Category
              </MDTypography>
              <Select
                required
                name="sub_category"
                closeMenuOnSelect={true}
                value={selectedSubCategory}
                onChange={(e) => setSelectedSubCategory(e)}
                options={subCategories?.filter((item) => item.category == selectedCategory)}
              />
            </MDBox>
            <MDButton
              variant="gradient"
              color="primary"
              type="submit"
              className="brand-card-background"
              loading={processing}
              disabled={processing}
              sx={{ mt: 4, mb: 1 }}
              fullWidth
            >
              Move
            </MDButton>
          </MDBox>
        </MDModal>
      </MDBox>
    )
  }
  return (
    <>
      <MDButton
        onClick={() => setopen(true)}
        variant="gradient"
        color="primary"
        sx={{ marginLeft: '10px' }}
      >
        Move
      </MDButton>
      <ModelWrappar open={open} onClose={() => setopen(false)} />
    </>
  )
}
export default MoveDoc
