import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import Editor from 'components/editor'
import { useAppServices } from 'hook/services'
import React, { useState } from 'react'

const index = ({ appData, handleRefresh }) => {
  const AppService = useAppServices()
  const [editor, setEditor] = useState(appData?.custom_js?.description || '')
  const handleSubmit = async (e) => {
    e.preventDefault()
    const custom_js = {
      name: e.target.name.value,
      description: editor,
    }
    const payload = {
      _id: appData._id,
      custom_js: custom_js,
    }
    // console.log(payload)

    const { response } = await AppService.app.update({
      payload: payload,
    })
    // console.log(response)
    if (response) {
      handleRefresh()
    }
  }
  return (
    <MDBox py={3} px={3} component="form" onSubmit={handleSubmit} role="form">
      <MDBox>
        <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
          Name
        </MDTypography>
        <MDInput label="Name" name="name" fullWidth defaultValue={appData?.custom_js?.name} />
      </MDBox>
      <MDBox>
        <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
          Description
        </MDTypography>
        <Editor
          handleChange={(data) => {
            setEditor(data)
          }}
          data={editor}
        />
      </MDBox>
      <MDBox display="flex" justifyContent="flex-end" mt={5}>
        <MDButton variant="gradient" color="info" type="submit">
          Save
        </MDButton>
      </MDBox>
    </MDBox>
  )
}

export default index
