/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from '@mui/material/Icon'

// Material Dashboard 2 React layouts
import Dashboard from './dashboard'
// import Tables from './tables'
// import Billing from './billing'
// import Notifications from './notifications'
import MissingFields from './Missing_Fields'
import Apps from './Apps'
import MarketplaceApps from './MarketplaceApps'
import AppPlans from './Apps/plans'
import Ticket from './Ticket'
import Health from './Health'
import Notes from './NotesTicket'
import Demo from './Demo/Demo'
import AppsView from './Apps/View'
import Auth from './integration/auth/index'
import ApiDocs from './Docs'
import Categories from './Categories'
import Events from './Events'
import Users from './Users'
import Locations from './Locations'
import Companies from './Companies'
import UnlimitedFaq from './UnlimitedFaq'
import Appinstalls from './Appinstalls'
import AppTokens from './AppTokens'
import SSO from './SSO'
import ToolTip from './ToolTip'
import AppLogs from './AppLogs'

import AgencyArticles from './AgencyArticles'
import AppListingCategory from './AppListingCategory'
import Languages from './Languages'
import AppListing from './AppListing'
import UpdateRequests from './UpdateRequests'

import AppListingView from './AppListing/View'
import Teams from './Teams'
import Faq from './Faq'
import K_faq from './k_faq'
function useRoutes() {
  return [
    // {
    //   type: 'collapse',
    //   name: 'Dashboard',
    //   key: 'dashboard',
    //   icon: <Icon fontSize="small">dashboard</Icon>,
    //   route: '/dashboard',
    //   component: <Dashboard />,
    // },
    {
      type: 'collapse',
      name: 'Agents',
      key: 'team',
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: '/team',
      component: <Teams />,
    },
    // {
    //   type: 'collapse',
    //   name: 'Customer',
    //   key: 'customer',
    //   icon: <Icon fontSize="small">dashboard</Icon>,
    //   route: '/customer',
    //   component: <Apps />,
    // },
    {
      type: 'collapse',
      name: 'Knowloedge Base',
      key: 'knowledgebase',
      route: '/knowledgebase',
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: '/knowledgebase/*',
      component: <ApiDocs />,
    },
    {
      type: 'collapse',
      name: 'Languages',
      key: 'languages',
      route: '/languages',
      icon: <Icon fontSize="small">languages</Icon>,
      route: '/languages/*',
      component: <Languages />,
    },
    {
      type: 'collapse',
      name: 'ToolTip',
      key: 'tooltip',
      route: '/tooltip',
      icon: <Icon fontSize="small">tooltip</Icon>,
      route: '/tooltip/*',
      component: <ToolTip />,
    },
    {
      type: 'collapse',
      name: 'Categories',
      key: 'categories',
      icon: <Icon fontSize="small">Categories</Icon>,
      route: '/categories',
      component: <Categories />,
    },
    // {
    //   type: 'collapse',
    //   name: 'Apps',
    //   key: 'apps',
    //   icon: <Icon fontSize="small">apps</Icon>,
    //   route: '/apps',
    //   component: <Apps />,
    // },
    {
      type: 'collapse',
      name: 'Marketplace Apps',
      key: 'marketplace_apps',
      icon: <Icon fontSize="small">apps</Icon>,
      route: '/marketplace_apps/*',
      component: <MarketplaceApps />,
    },
    {
      key: 'apps',
      route: '/apps/:app_id',
      component: <AppsView />,
    },
    {
      key: 'apps',
      route: '/apps/plans/:app_id',
      component: <AppPlans />,
    },
    {
      type: 'collapse',
      name: 'App Listing Category',
      key: 'apps_listing_category',
      icon: <Icon fontSize="small">apps_listing_category</Icon>,
      route: '/apps_listing_category',
      component: <AppListingCategory />,
    },
    {
      type: 'collapse',
      name: 'App Listing',
      key: 'apps_listing',
      icon: <Icon fontSize="small">apps_listing</Icon>,
      route: '/apps_listing',
      component: <AppListing />,
    },
    {
      type: 'collapse',
      name: 'App Update Request',
      key: 'update_requests',
      icon: <Icon fontSize="small">apps_listing</Icon>,
      route: '/update_requests',
      component: <UpdateRequests />,
    },
    {
      key: 'apps_listing',
      route: '/apps_listing/:app_id',
      component: <AppListingView />,
    },
    {
      type: 'collapse',
      name: 'events',
      key: 'events',
      icon: <Icon fontSize="small">events</Icon>,
      route: '/events',
      component: <Events />,
    },
    {
      type: 'collapse',
      name: 'Missing Fields',
      key: 'missing_fields',
      icon: <Icon fontSize="small">missing_fields</Icon>,
      route: '/missing_fields',
      component: <MissingFields />,
    },
    {
      type: 'collapse',
      name: 'SSO',
      key: 'sso',
      icon: <Icon fontSize="small">Categories</Icon>,
      route: '/sso',
      component: <SSO />,
    },
    {
      type: 'collapse',
      name: 'App Logs',
      key: 'applogs',
      icon: <Icon fontSize="small">applogs</Icon>,
      route: '/applogs',
      component: <AppLogs />,
    },
    {
      type: 'collapse',
      name: 'Users',
      key: 'users',
      icon: <Icon fontSize="small">users</Icon>,
      route: '/users',
      component: <Users />,
    },
    {
      type: 'collapse',
      name: 'Locations',
      key: 'locations',
      icon: <Icon fontSize="small">locations</Icon>,
      route: '/locations',
      component: <Locations />,
    },
    {
      type: 'collapse',
      name: 'Companies',
      key: 'companies',
      icon: <Icon fontSize="small">companies</Icon>,
      route: '/companies',
      component: <Companies />,
    },
    {
      type: 'collapse',
      name: 'Appinstalls',
      key: 'appinstalls',
      icon: <Icon fontSize="small">appinstalls</Icon>,
      route: '/appinstalls',
      component: <Appinstalls />,
    },

    // {
    //   type: 'collapse',
    //   name: 'Tickets',
    //   key: 'ticket',
    //   icon: <Icon fontSize="small">dashboard</Icon>,
    //   route: '/ticket',
    //   component: <Ticket />,
    // },
    {
      type: 'collapse',
      name: 'Faq',
      key: 'faq',
      icon: <Icon fontSize="small">faq</Icon>,
      route: '/faq',
      component: <Faq />,
    },
    {
      type: 'collapse',
      name: 'Agency Faq',
      key: 'unlimited_faq',
      icon: <Icon fontSize="small">faq</Icon>,
      route: '/unlimited_faq',
      component: <UnlimitedFaq />,
    },
    {
      type: 'collapse',
      name: 'Knowledge Base Faq',
      key: 'k_faq',
      icon: <Icon fontSize="small">faq</Icon>,
      route: '/k_faq',
      component: <K_faq />,
    },

    {
      type: 'collapse',
      name: 'App Tokens',
      key: 'apptokens',
      icon: <Icon fontSize="small">d</Icon>,
      route: '/apptokens',
      component: <AppTokens />,
    },
    {
      key: 'theme',
      route: '/theme',
      component: <Demo />,
    },
    {
      key: 'auth',
      route: '/auth',
      component: <Auth />,
    },
  ]
}

export default useRoutes
