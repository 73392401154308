import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import Editor from 'components/editor'
import { useAppServices } from 'hook/services'
import React, { useState } from 'react'

const index = ({ appData, handleRefresh, type, description }) => {
  const AppService = useAppServices()
  const [editor, setEditor] = useState(description || '')
  const handleSubmit = async (e) => {
    e.preventDefault()
    const payload = {
      _id: appData._id,
    }
    // console.log(payload)
    if (type == 'agency') {
      payload['agency_description'] = editor
    } else {
      payload['loc_description'] = editor
    }
    const { response } = await AppService.app.update({
      payload: payload,
    })
    // console.log(response)
    if (response) {
      handleRefresh()
    }
  }
  return (
    <MDBox py={3} px={3} component="form" onSubmit={handleSubmit} role="form">
      <MDBox>
        <Editor
          handleChange={(data) => {
            setEditor(data)
          }}
          data={editor}
        />
      </MDBox>
      <MDBox display="flex" justifyContent="flex-end" mt={5}>
        <MDButton variant="gradient" color="info" type="submit">
          Save
        </MDButton>
      </MDBox>
    </MDBox>
  )
}

export default index
