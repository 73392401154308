/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/

// @mui material components
import { Card } from '@mui/material'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

// MD UI Dashboard PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// MD UI Dashboard PRO React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import AppData from './components/data'
import MDButton from 'components/MDButton'
import CreateModal from './components/Modals/Create'
import { useEffect, useState } from 'react'
import { useAppServices } from 'hook/services'
import { useAgencyInfo } from 'context/agency'
import Loader from 'examples/Loader'
import Multiselect from './components/MultiSelect'

function Default() {
  const AppService = useAppServices()
  const [agency] = useAgencyInfo()
  const [skip, setSkip] = useState(0)
  const [categoryLoader, setcategoryLoader] = useState(true)
  const [maindata, setMainData] = useState([])
  const [Categories, setCategories] = useState([])
  const [activeCateory, setactiveCateory] = useState('')
  const { loader, TableData, handleRefresh, languages } = AppData({ activeCateory })
  const [refresh, setRefresh] = useState(false)
  const getDocsCategory = async () => {
    const { response, error } = await AppService.docs_category.filter({
      query: `type=super-admin`,
    })
    // console.log(response, 'response')
    if (response) {
      setCategories(
        response.data.map((item) => ({
          ...item,
          label: item.name,
          value: item._id,
        }))
      )
      setcategoryLoader(false)
    } else {
      setCategories([])
      setcategoryLoader(false)
    }
  }
  const onLoad = () => {
    getDocsCategory()
  }

  useEffect(onLoad, [])
  return (
    <>
      {categoryLoader ? (
        <Loader />
      ) : (
        <>
          <MDBox>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <MDBox>
                  {/* <MDBox
                    mx={2}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center  "
                  >
                    <MDTypography variant="h6" color="white">
                      Docs
                    </MDTypography>
                  </MDBox> */}
                  <MDBox sx={{ fontSize: '15px' }} px={5} mt={2}>
                    <MDTypography
                      variant="button"
                      sx={{ fontSize: '14px', fontWeight: '500' }}
                      mb={1}
                    >
                      Select Category
                    </MDTypography>
                    <Multiselect
                      data={Categories}
                      edit_data={{}}
                      isMulti={false}
                      onChange={(e) => setactiveCateory(e.value)}
                    />
                  </MDBox>
                  {!loader && (
                    <MDBox pt={3} px={3}>
                      <DataTable table={TableData} canSearch entriesPerPage={5} />
                    </MDBox>
                  )}
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
    </>
  )
}

export default Default
