/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// Data
import { useState } from 'react'
import MDButton from 'components/MDButton'
import DataTable from 'examples/Tables/DataTable'
import SubCategoryData from '../data/SubCategoryData'
import SubCategoryModal from './create'
function Docs() {
  const { columns, rows, getDocsSubCat } = SubCategoryData()
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="warning"
                display="flex"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Sub Category
                </MDTypography>
                <MDButton
                  onClick={() => setOpen(true)}
                  sx={{
                    marginLeft: 'auto',
                    // '10px'
                  }}
                  variant="outlined"
                >
                  Create
                </MDButton>
                <SubCategoryModal open={open} onClose={handleClose} handleRefresh={getDocsSubCat} />
              </MDBox>
              <DndProvider backend={HTML5Backend}>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </DndProvider>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default Docs
